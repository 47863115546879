import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import Login from './Login';
import Video from './Video/Video';
import PrivateRoute from './PrivateRoute';
import NotFound from './NotFound';
import { history } from '../_helpers/history';
import { connect } from 'react-redux';
import Permiso from './Video/Permiso';
import PermisoAudio from './Video/PermisoAudio';
import RegistroActividad from './Video/RegistroActividad';



const App = ({handleRecoverSession}) => {
  // if session exist's is stored on redux store
  function getTokenFromLocalStorage() {
    const ls = JSON.parse(localStorage.getItem('PitzUser'));
    if (ls && ls.token) handleRecoverSession(ls);
  }

  React.useEffect(() => {
    getTokenFromLocalStorage();
  });

  return (
    <div className="App">
      <Router history={history}>
        <Switch>
          <Route exact path="/" component={Login} />
          <PrivateRoute path="/video" component={Video} />
          <PrivateRoute path="/permiso" component={Permiso} />
          <PrivateRoute path="/permiso-audio" component={PermisoAudio} />
          <PrivateRoute path="/registro-actividad" component={RegistroActividad} />

          <Route component={NotFound} />
        </Switch>
      </Router>
    </div>
  );
}

const mapDispatch = dispatch => ({
  handleRecoverSession: (session) => dispatch.auth.recoverUserSession(session)
});

export default connect(null, mapDispatch)(App);
