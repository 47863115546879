import React from 'react';
import css from './Scoreboard.module.css';
import img from '../../../img/escudo_pitz.png';

const Scoreboard = ({info}) => {
  return (
    <div className={`${css.Scoreboard} pr-4 py-3`}>
      <p className={`m-0 pr-3 ${css['Scoreboard-team-label']}`}>{info && info.team_a.name}</p>
      <div className={css['Scoreboard-team-thumb']}
        style={{
          backgroundImage: info && info.team_a.logo ? `url(${info.team_a.logo})` : `url(${img})`,
          backgroundSize: info && info.team_a.logo ? 'cover' : 'contain'
        }} />
      <p className={css['Scoreboard-team-score']}>
        {info && info.team_a.gol ? info.team_a.gol : '-'}
      </p>

      <p className={css['Scoreboard-team-score']}>{info && info.team_b.gol ? info.team_b.gol : '-'}</p>
      <div className={css['Scoreboard-team-thumb']}
        style={{
          backgroundImage: info && info.team_b.logo ? `url(${info.team_b.logo})` : `url(${img})`,
          backgroundSize: info && info.team_a.logo ? 'cover' : 'contain'
        }} />
      <p className={`m-0 pl-3 ${css['Scoreboard-team-label']}`}>
        {info && info.team_b.name}
      </p>
    </div>
  );
};

export default Scoreboard;
