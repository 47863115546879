import React from 'react';
import Header from '../Header/Header';
import { Button,Container,Row,Col,Card,Form } from 'react-bootstrap'
import appAxios from '../../axios';
import {Trans} from "react-i18next";
class RegistroActividad extends React.Component {

    constructor(props) {
        super(props);
        this.state = { 
          lunes_c:false,  
          martes_c:false,  
          miercoles_c:false,  
          jueves_c:false,  
          viernes_c:false,  
          sabado_c:false,  
          domingo_c:false,  
          submitted: false
        };
    }


    handleChange = (e) => {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    }

    handleChange2 = (e) => {
      const { name } = e.target;
      this.setState({ [name]: e.target.checked });
    }

    handleSubmit = (e) => {
      e.preventDefault();
      this.setState(prevState => ({ ...prevState, submitted: true }));

      let time_dedicated ={
          lunes : this.state.lunes_c ? this.state.lunes_i : 0,
          martes : this.state.martes_c ? this.state.martes_i : 0,
          miercoles : this.state.miercoles_c ? this.state.miercoles_i : 0,
          jueves : this.state.jueves_c ? this.state.jueves_i : 0,
          viernes : this.state.viernes_c ? this.state.viernes_i : 0,
          sabado : this.state.sabado_c ? this.state.sabado_i : 0,
          domingo : this.state.domingo_c ? this.state.domingo_i : 0,
      }

        const {salario, tiempo_narracion, lunes_c, martes_c, miercoles_c, jueves_c, viernes_c, sabado_c, domingo_c } = this.state;

      if(salario && tiempo_narracion && (lunes_c || martes_c || miercoles_c || jueves_c || viernes_c || sabado_c || domingo_c)){
          appAxios.post(`/register-activity`, { 
            time_dedicated: time_dedicated,
            salary: this.state.salario,
            time_narration: this.state.tiempo_narracion,
            comments: this.state.comentarios
          
          })
          .then(response => {
            localStorage.removeItem('PitzUser');
            window.location.href = "https://pitz.app/graciasporaudicionar/";
          })
          .catch(error => {
            this.setState({ submitting: false});
          });
      }
  
    }
  

  render () {

    const { lunes_c, martes_c, miercoles_c, jueves_c, viernes_c, sabado_c, domingo_c , submitted } = this.state;
    let message
    if(submitted && (!lunes_c && !martes_c && !miercoles_c && !jueves_c && !viernes_c && !sabado_c && !domingo_c)){
      message = <small style={{color: "red"}} >Selecciona almenos un dia</small>
    }else{
      message = "";
    }

    return (
        <>
        <Header />
        <Container className="mt-3">
          <Row>
            <Col xs="8" className="m-auto">
              <Card>
                <Card.Header>
                  <Trans i18nKey="survey.title"></Trans>
                </Card.Header>

                <Card.Body>
                  <Form onSubmit={this.handleSubmit}>

                    <Form.Group controlId="formGroupEmail">
                      <Form.Label> 
                        <Trans i18nKey="survey.top_label_timer"></Trans>
                      </Form.Label>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Col xs={3} >
                        <Form.Check inline label={<Trans i18nKey="survey.monday"></Trans>} name="lunes_c" checked={lunes_c} onChange={this.handleChange2}/>
                      </Col>
                      <Col xs={4}>
                        <Form.Control type="number" placeholder="" name="lunes_i" step="0.1" onChange={this.handleChange} disabled={!lunes_c} />
                      </Col>

                      <Col xs={4}  className="pl-0">
                        <Trans i18nKey="survey.hours"></Trans>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} >
                      <Col xs={3} >
                        <Form.Check inline label={<Trans i18nKey="survey.tuesday"></Trans>} name="martes_c" checked={martes_c} onChange={this.handleChange2} />
                      </Col>
                      <Col xs={4}>
                        <Form.Control type="number" placeholder="" name="martes_i" step="0.1" onChange={this.handleChange} disabled={!martes_c}/>
                      </Col>
                      <Col xs={4}  className="pl-0">
                        <Trans i18nKey="survey.hours"></Trans>
                      </Col>

                    </Form.Group>

                    <Form.Group as={Row} >
                      <Col xs={3} >
                        <Form.Check inline label={<Trans i18nKey="survey.wednesday"></Trans>} name="miercoles_c" checked={miercoles_c} onChange={this.handleChange2} />
                      </Col>
                      <Col xs={4}>
                        <Form.Control type="number" placeholder="" name="miercoles_i" step="0.1" onChange={this.handleChange} disabled={!miercoles_c} />
                      </Col>
                      <Col xs={4}  className="pl-0">
                      <Trans i18nKey="survey.hours"></Trans>
                      </Col>

                    </Form.Group>

                    <Form.Group as={Row} >
                      <Col xs={3} >
                        <Form.Check inline label={<Trans i18nKey="survey.thursday"></Trans>}  name="jueves_c" checked={jueves_c} onChange={this.handleChange2}/>
                      </Col>
                      <Col xs={4}>
                        <Form.Control type="number" placeholder="" name="jueves_i" step="0.1" onChange={this.handleChange} disabled={!jueves_c} />
                      </Col>
                      <Col xs={4}  className="pl-0">
                        <Trans i18nKey="survey.hours"></Trans>
                      </Col>

                    </Form.Group>

                    <Form.Group as={Row} >
                      <Col xs={3} >
                        <Form.Check inline label={<Trans i18nKey="survey.friday"></Trans>}  name="viernes_c" checked={viernes_c} onChange={this.handleChange2}/>
                      </Col>
                      <Col xs={4}>
                        <Form.Control type="number" placeholder="" name="viernes_i" step="0.1" onChange={this.handleChange} disabled={!viernes_c}/>
                      </Col>
                      <Col xs={4}  className="pl-0">
                      <Trans i18nKey="survey.hours"></Trans>
                      </Col>

                    </Form.Group>

                    <Form.Group as={Row} >
                      <Col xs={3} >
                        <Form.Check inline label={<Trans i18nKey="survey.saturday"></Trans>}  name="sabado_c" checked={sabado_c} onChange={this.handleChange2}/>
                      </Col>
                      <Col xs={4}>
                        <Form.Control type="number" placeholder="" name="sabado_i" step="0.1" onChange={this.handleChange} disabled={!sabado_c} />
                      </Col>
                      <Col xs={4}  className="pl-0">
                        <Trans i18nKey="survey.hours"></Trans>
                      </Col>

                    </Form.Group>

                    <Form.Group as={Row} >
                      <Col xs={3} >
                        <Form.Check inline label={<Trans i18nKey="survey.sunday"></Trans>}  name="domingo_c" checked={domingo_c} onChange={this.handleChange2} />
                      </Col>
                      <Col xs={4}>
                        <Form.Control type="number" placeholder="" name="domingo_i" step="0.1" onChange={this.handleChange} disabled={!domingo_c} />
                      </Col>
                      <Col xs={4}  className="pl-0">
                        <Trans i18nKey="survey.hours"></Trans>
                      </Col>

                    </Form.Group>

                    <Form.Group >
                      {message}
                    </Form.Group>


                    <Form.Group >
                      <Form.Label> 
                      <Trans i18nKey="survey.label_salary.text"></Trans>
                      </Form.Label>
                      <Form.Label>
                      <Trans i18nKey="survey.label_salary.text2"></Trans>
                      </Form.Label>
                    </Form.Group>

                    <Form.Group as={Row} >
                      <Col xs={4}>
                        <Form.Control type="number" name="salario" step="0.1" onChange={this.handleChange}  /> 
                      </Col>
                      <Col xs={6}  className="pl-0"><Trans i18nKey="survey.label_input_salary"></Trans></Col>

                      {submitted && !this.state.salario &&
                        <small style={{color: "red"}} >Campo requerido</small>
                      }
                    </Form.Group>

                    <Form.Group as={Row} >
                      <Form.Label>
                      <Trans i18nKey="survey.label_time_narration"></Trans>  
                      </Form.Label>
                      <Col xs={4} className="pl-3">
                        <Form.Control type="number" name="tiempo_narracion" onChange={this.handleChange}  /> 
                      </Col>
                      <Col xs={6}  className="pl-0">
                      <Trans i18nKey="survey.label_input_narration"></Trans>
                      </Col>
                      {submitted && !this.state.tiempo_narracion &&
                        <small style={{color: "red"}} >Campo requerido</small>
                      }

                    </Form.Group>


                    <Form.Group >
                      <Form.Label>
                        <Trans i18nKey="survey.label_comment"></Trans>
                      </Form.Label>
                      <Form.Control as="textarea" rows="3" name="comentarios" onChange={this.handleChange}/>
                    </Form.Group>


                    
                    
                  

                    



                    <Button variant="primary" type="submit">
                      <Trans i18nKey="survey.btn_save"></Trans>
                    </Button>
                  </Form>
                    
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}

export default RegistroActividad;
