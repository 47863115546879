import React from 'react';
import Header from './Header/Header';
import { Container, Alert } from 'react-bootstrap';

const NotFound = () => (
  <>
    <Header />
    <Container className='mt-5'>
      <Alert variant="danger" >
      <Alert.Heading>Pagina No Encontrada</Alert.Heading>
        <a href="/" className='btn btn-primary'>Ir a inicio</a>
      </Alert>
    </Container>
  </>
);

export default NotFound;
