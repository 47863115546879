import React from 'react';
import Header from '../Header/Header';
import { Button } from 'react-bootstrap'
import { history } from '../../_helpers/history';
import {Trans} from "react-i18next";

class PermisoAudio extends React.Component {


    componentWillMount(){
    }
    componentDidMount() {
        navigator.mediaDevices.getUserMedia({audio:true}).then(function(mediaStream) {
          }).catch(function(err) {
            /* manejar el error */
          });
    }
    Permision = () =>{
        history.push('/video');
    }

  render () {

    return (
      <div className='min-h-screen custom-bg-secondary'>
            <Header />

            <div className='container'>
                <div className="h-100 row align-items-center">
                    <div className="col-12 text-center">
                        <h1 className='text-white message-centered'>
                          <Trans i18nKey="permission.text"></Trans>
                        </h1>
                        <Button variant="info" onClick={this.Permision}>
                          <Trans i18nKey="permission.btn"></Trans>
                        </Button>
                    </div>
                </div>
            </div>

      </div>
    )
  }
}

export default PermisoAudio;
