import axios from 'axios';

const appAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

appAxios.interceptors.request.use(
  request => {
    const ls = JSON.parse(localStorage.getItem('PitzUser'));
    const token = ls ? ls.token : null;
    const updatedReq = { ...request };

    if (
      token &&
      request.url.indexOf('auth') === -1 &&
      request.url.indexOf('restcountries') === -1
    ) {
      updatedReq.headers.Authorization = `Bearer ${token}`;
    }
    return updatedReq;
  },
  error => {
    console.log(error.response);
    return Promise.reject(error);
  }
);

export default appAxios;
