import { history } from '../../_helpers/history';
import appAxios from '../../axios';

const auth = {
  state: {
    user: null,
    token: null,
    error: null,
    status: ''
  },
  reducers: {
    // SIGN IN
    signin: state => ({ ...state, status: 'waiting', error: null }),
    signinSuccess: (state, res) => ({
      ...state,
      status: 'received',
      token: res.token,
      user: res.user
    }),
    signinFailed: (state, error) => ({ ...state, status: 'failed', error }),

    registerUser: state => ({ ...state, status: 'waiting', error: null }),
    registerUserSuccess: (state, res) => ({
      ...state,
      status: 'received',
      token: res.token,
      user: res.user
    }),
    registerUserFailed: (state, error) => ({ ...state, status: 'failed', error }),


    // RECOVER SESSION
    recoverUserSession: (state, session) => ({
      ...state,
      token: session.token,
      user: { ...session.user },
      status: 'received'
    })
  },
  effects: dispatch => ({
    // SIGN OUT
    signout() {
      localStorage.removeItem('PitzUser');
      dispatch({ type: 'RESET_APP' });
      history.push('/');
    },

    // SIGN IN
    signin(body) {
      appAxios.post('/login', body)
        .then(res => {
          this.signinSuccess(res.data.data);
          localStorage.setItem('PitzUser', JSON.stringify(res.data.data));
          history.push('/video');
        })
        .catch(error => {
          if (error.response) {
            this.signinFailed(error.response.data);
          } else {
            this.signinFailed(error);
          }
        });
    },
    registerUser(body) {
      appAxios.post('/register', body)
        .then(res => {
          this.signinSuccess(res.data.data);
          localStorage.setItem('PitzUser', JSON.stringify(res.data.data));
          history.push('/permiso');
        })
        .catch(error => {
          if (error.response) {
            this.signinFailed(error.response.data);
          } else {
            this.signinFailed(error);
          }
        });
    }
  })
};

export default auth;
