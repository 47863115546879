import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({component: Component, authed, ...rest}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem('PitzUser') && JSON.parse(localStorage.getItem('PitzUser')).token
          ? <Component {...props} />
          : <Redirect to={{pathname: '/login', state: {from: props.location}}} />}
    />
  )
}
export default PrivateRoute;
