import React from 'react'
import { Navbar} from 'react-bootstrap';
import { connect } from 'react-redux';
import css from './Header.module.css';

const Header = ({ auth, signout }) => (
  <Navbar expand="lg" variant="dark" className={css.Header}>
    <Navbar.Brand href="#">Pitz</Navbar.Brand>

  </Navbar>
);

const mapDispatch = dispatch => ({
  signout: () => dispatch.auth.signout()
});

const mapState = state => ({
  auth: state.auth
});

export default connect(mapState, mapDispatch)(Header);
