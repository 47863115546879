import { init } from '@rematch/core';
import auth from './models/auth';

const store = init({
    redux: {
      rootReducers: { RESET_APP: () => undefined },
      devtoolOptions: { disabled: process.env.NODE_ENV === 'production' }
    },
    models: { auth }
  });

  export default store;
